<template>
    <div class="create-referral">
        <el-row>
            <el-col :xs="12" :md="7" :sm="9" :lg="5" :span="5">
                <div @click="updateReferralFlag" class="new-referral-btn place-center">
                    Add New Candidate
                </div>
            </el-col>
        </el-row>
        <el-drawer v-model="referCandidateFlag" direction="rtl" size="60%" ref="drawer">
            <div class="new-referral-cover">
                <el-form class="referral-form" :model="referralObj" :rules="rules" ref="referralObj"
                    @submit="$event.preventDefault()" @keypress.enter="createReferral($event)">
                    <div class="place-between pace-icon">
                        <div>
                            <p class="drawer-title">Please fill the following details for referral</p>
                        </div>
                    </div>

                    <div class="input-group">
                        <label>Referee Name</label>
                        <el-form-item prop="referralName">
                            <el-input type="text" @keypress.enter.prevent placeholder="Please Enter Name"
                                v-model="referralObj.referralName">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="input-group">
                        <label>Referee Email</label>
                        <el-form-item prop="referralEmail">
                            <el-input type="text" @keypress.enter.prevent placeholder="ex: name@example.com"
                                v-model="referralObj.referralEmail">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="input-group">
                        <label>Referee Phone Number</label>
                        <el-form-item prop="referralPhone">
                            <el-input type="text" @keypress.enter.prevent placeholder="+ (00) 000 000 0000"
                                v-model="referralObj.referralPhone">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="input-group">
                        <label>Current Company/Position</label>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item prop="referralCompanyName">
                                    <el-input type="text" @keypress.enter.prevent placeholder="Current Company name"
                                        v-model="referralObj.referralCompanyName">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="companyPosition">
                                    <el-input type="text" @keypress.enter.prevent placeholder="Current position"
                                        v-model="referralObj.companyPosition">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="input-group">
                        <label>Experience of the candidate range (in year)</label>
                        <el-form-item prop="candidateExp">
                            <el-input type="text" @keypress.enter.prevent placeholder="ex: 1.2 or 3.6 years"
                                v-model="referralObj.candidateExp">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="input-group">
                        <label>Notice period of the candidate (in month)</label>
                        <el-form-item prop="candidateNoticePeriod">
                            <el-input type="text" @keypress.enter.prevent placeholder="Mention in months"
                                v-model="referralObj.candidateNoticePeriod">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="input-group">
                        <label>Relationship with the candidate</label>
                        <el-form-item prop="candidateRelation">
                            <el-input type="text" @keypress.enter.prevent placeholder="Mention in 2-3 words"
                                v-model="referralObj.candidateRelation">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="input-group">
                        <label>Resume/CV</label>
                        <el-upload :accept="allowedFileTypes" :on-remove="handleOnRemove" :on-change="handleOnChange" :limit=1 :auto-upload="false" ref="file"
                            action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" class="upload-area"
                            drag>
                            <div class="block2">
                            <div >
                                <img width="50" height="60" src="../../assets/images/upload.svg" />
                                <p class="bold-text">Browse Files</p>
                            </div>
                            <div class="el-upload__text">
                                <p>Drag and drop files here</p>
                            </div>
                            </div>
                        </el-upload>
                    </div>

                    <div class="input-group">
                        <label>Comments</label>
                        <el-form-item prop="desc">
                            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }"
                                placeholder="Enter Comment" v-model="referralObj.desc" maxlength="256"
                                show-word-limit>
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="action-btn">
                        <button class="btn close-btn color-black" @click="updateReferralFlag">
                            Close
                        </button>
                        <el-button class="btn submit-btn primary-bg color-white" native-type="submit"
                            @click="createReferral($event)" :loading="createReferralLoading">
                            Submit
                        </el-button>
                    </div>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ReferCandidate from "../../services/refercandidate.js";

export default {
    name: "ReferralForm",
    props: ['referralId'],
    data() {
        return {
            createReferralLoading: false,
            centerDialogVisible: false,
            file: '',
            referralObj: {
                referralName: "",
                referralEmail: "",
                referralPhone: "",
                referralCompanyName: "",
                companyPosition: "",
                candidateExp: "",
                candidateNoticePeriod: "",
                candidateRelation: ""
            },
            allowedFileTypes: 'application/pdf, application/msword, text/plain',
            rules: {
                referralName: [
                    {
                        required: true,
                        message: "name is required.",
                        trigger: "blur",
                    },
                ],
                referralEmail: [
                    {
                        required: true,
                        message: "email is required.",
                        trigger: "blur",
                    },
                ],
                referralPhone: [
                    {
                        required: true,
                        message: "phone number is required.",
                        trigger: "blur",
                    },
                ],
                referralCompanyName: [
                    {
                        required: true,
                        message: "company name is required.",
                        trigger: "blur",
                    },
                ],
                companyPosition: [
                    {
                        required: true,
                        message: "current position is required.",
                        trigger: "blur",
                    },
                ],
                candidateExp: [
                    {
                        required: true,
                        message: "candidate experience is required.",
                        trigger: "blur",
                    },
                ],
                candidateNoticePeriod: [
                    {
                        required: true,
                        message: "candidate notice period is required.",
                        trigger: "blur",
                    },
                ],
                candidateRelation: [
                    {
                        required: true,
                        message: "candidate relation is required.",
                        trigger: "blur",
                    },
                ]

            },
            referCandidateFlag: false,
        };
    },
    methods: {
        handleOnChange(file) {
            this.file = file;
        },
        handleOnRemove() {
            this.file = "";
        },
        createReferral() {
            if (this.file && this.file !== "" && this.file !== null) {
                this.$refs["referralObj"].validate((valid) => {
                    if (valid && !this.createReferralLoading) {
                        this.createReferralLoading = true;
                        const fd = new FormData()
                        fd.append('resume', this.file.raw, this.file.name);
                        fd.append('referee_name', this.referralObj.referralName);
                        fd.append('referee_email', this.referralObj.referralEmail);
                        fd.append('referee_phone', this.referralObj.referralPhone);
                        fd.append('company_name', this.referralObj.referralCompanyName);
                        fd.append('company_position', this.referralObj.companyPosition);
                        fd.append('experience', this.referralObj.candidateExp);
                        fd.append('notice_period', this.referralObj.candidateNoticePeriod);
                        fd.append('relationship', this.referralObj.candidateRelation);
                        fd.append('note', this.referralObj.desc);
                        ReferCandidate.createReferral(this.referralId, fd)
                            .then(() => {
                                this.createReferralLoading = false;
                                this.referCandidateFlag = false;
                                this.resetmodal();
                                this.$emit('getReferralList')
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else {
                        return;
                    }
                });
            } else {
                ElNotification.error({
                    title: "Error",
                    message: 'Resume/CV is required.',
                    position: "top-right"
                });
            }
        },
        resetmodal() {
            this.$refs["referralObj"].resetFields();
        },
        updateReferralFlag() {
            this.referCandidateFlag = !this.referCandidateFlag;
        },
    },
};
</script>

<style lang="scss" scoped>
.new-referral-btn {
    width: 200px;
    height: 35px;
    border: 1px solid $primary;
    cursor: pointer;
    margin: 18px;
    font-weight: 500;

    &:hover {
        background: $primary;
        color: #fff;
        margin: 18px;
    }
}

.bold-text {
    font-size: 15px;
    font-weight: bold;
}

.drawer-title {
    font-size: 22px;
}

label {
    font-size: 15px;
}

.new-referral-cover {
    width: 600px;
    margin: auto;

    @media (max-width: 960px) {
        width: 540px;
        display: grid;
        place-content: left;
        margin: auto;
    }

    .action-btn {
        display: flex;
        padding: 20px;
        justify-content: space-around;

        .submit-btn,
        .close-btn {
            width: 35%;
            margin-right: 10px;
        }
    }
}
</style>
