<template>
	<section class="refer-candidate">
		<header class="header">
			<TopNavbar @clicked="sideNavEvent" :navFlag="navFlag" :name="name" :profile_pic="profilePic" :role="role" />
		</header>
	</section>

	<section class="refer-candidate">
		<div class="container">
			<div class="header-container">
				<h2 class="heading">Refer Candidate</h2>
			</div>
		</div>
		<ReferralList :roleId="roleId" :role="role" />
	</section>
</template>

<script>

import TopNavbar from "@/components/TopNavbar.vue";
import ReferralList from "../components/opening/referralList.vue";

export default {
	name: "Refer Candidate",

	data() {
		return {
			profilePic: localStorage.getItem("profile_pic"),
			username: localStorage.getItem("username"),
			navFlag: false,
			loading: true,
			roleId: this.$route.params.referralId,
			role: this.$route.params.role ? this.$route.params.role : localStorage.getItem("role")
		};
	},

	components: {
		TopNavbar,
		ReferralList
	},
};
</script>


<style lang="scss" scoped>
.container {
	margin-left: 2rem;
	margin-right: 2rem;
	border-bottom-color: black;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	flex-direction: row;
	display: flex;
	justify-content: center;
}

.header-container {
	flex-direction: row;
	justify-content: center;
}

.data-container {
	margin-right: 100px;
	margin-left: 100px;
	flex-direction: row;
	display: flex;
	justify-content: center;
}

.heading {
	text-align: center;
}

.new-referral-btn {
  width: 150px;
  height: 35px;
  border: 1px solid $primary;
  cursor: pointer;
  margin: 18px;
  font-weight: 500;

  &:hover {
    background: $primary;
    color: #fff;
    margin: 18px;
  }
}
</style>