import http from ".././http-common.js";

export default {
    //get all projects
    ProjectList() {
        let id = localStorage.getItem("P1");
        let status = true
        return http.get(`/timesheet/api/project/list/${id}/${status}/`).then(res => {
            return res;
        });
    },

    HistoryProjectList() {
        let id = localStorage.getItem("P1");
        let status = false
        return http.get(`/timesheet/api/project/list/${id}/${status}/`).then(res => {
            return res;
        });
    },

    SearchedProjectList(data) {
        let id = localStorage.getItem("P1");
        return http.get(`/timesheet/api/searched_project/list/${id}/${data}/`).then(res => {
            return res;
        });
    },

    //to get list of project docs that are enabled as accessible to the project members by admin
    DocsOfProject(proj_id) {
        return http.get(`/timesheet/api/project_document/${proj_id}/`).then(res => {
            return res;
        });
    },
};
