import http from "../http-common.js";

export default {
  async getReferralList(referralId) {
    return http
      .get("/employee_referral/api/referral_list/" + referralId)
      .then((res) => {
        return res;
      });
  },
  async createReferral(referralId, data) {
    const res = await http.post(
      "/employee_referral/api/referral_post/" + referralId + "/",
      data
    );
    return res;
  },

  async getReferralDetails(id) {
    const res = await http.get(
      "/employee_referral/api/referral_details_list/" + id + "/"
    );
    return res;
  },

  async addComments(id, data) {
    const res = await http.put(
      "/employee_referral/api/referral_comments/" + id + "/",
      data
    );
    return res;
  },

  async getMyReferralList() {
    const res = await http.get("/employee_referral/api/my_referral/");
    return res;
  },

  async editReferral(id, data) {
    const res = await http.put(
      "/employee_referral/api/referral_details_edit/" + id + "/",
      data
    );
    return res;
  },
};
