<template>
	<section class="home-page">
		<header class="header">
			<TopNavbar
				@clicked="sideNavEvent"
				:navFlag="navFlag"
				:name="name"
				:profile_pic="profilePic"
				:role="role"
			/>
		</header>
	</section>

	<section class="skil-matrix">
		<div class="task-intro container">
			<div style="float: right">
				<el-button type="primary" icon="el-icon-user" @click="addSkills"
					>Add Skills</el-button
				>
			</div>
			<h2 class="heading" style="text-align: center">Your Skills</h2>
			<el-divider></el-divider>
		</div>
		<!-- Action btn -->
		<div class="container">
			<div v-if="isEmpty">
				<el-empty :image-size="200" />
			</div>
			<section class="task-list">
				<el-row :gutter="12">
					<el-col :span="12" v-if="techSkills.length">
						<el-card shadow="always">
							<div class="task-intro container">
								<h4 class="heading" style="text-align: center">
									My Technical Skills
								</h4>
							</div>
							<el-table :data="techSkills" height="250" style="width: 100%">
								<el-table-column prop="tech" label="Technology" width="230" />
								<el-table-column prop="level" label="Skill Level" width="230" />
								<el-table-column label="Action" width="180" #default="scope">
									<el-button type="text" @click="EditSkillMatrix(scope)">
										<fa class="edit" :icon="['fas', 'edit']" />
									</el-button>
								</el-table-column>
							</el-table>
						</el-card>
					</el-col>
					<el-col :span="12" v-if="fwSkills.length">
						<el-card shadow="always">
							<div class="task-intro container">
								<h4 class="heading" style="text-align: center">
									My Framework Skills
								</h4>
							</div>
							<el-table :data="fwSkills" height="250" style="width: 100%">
								<el-table-column prop="tech" label="Framework" width="230" />
								<el-table-column prop="level" label="Skill Level" width="230" />
								<el-table-column label="Action" width="180" #default="scope">
									<el-button type="text" @click="EditSkillMatrix(scope)">
										<fa class="edit" :icon="['fas', 'edit']" />
									</el-button>
								</el-table-column>
							</el-table>
						</el-card>
					</el-col>
					<el-col :span="12" v-if="dbSkills.length">
						<el-card shadow="always">
							<div class="task-intro container">
								<h4 class="heading" style="text-align: center">
									My Database Skills
								</h4>
							</div>
							<el-table :data="dbSkills" height="350" style="width: 100%">
								<el-table-column prop="tech" label="Database" width="230" />
								<el-table-column prop="level" label="Skill Level" width="230" />
								<el-table-column label="Action" width="180" #default="scope">
									<el-button type="text" @click="EditSkillMatrix(scope)">
										<fa class="edit" :icon="['fas', 'edit']" />
									</el-button>
								</el-table-column>
							</el-table>
						</el-card>
					</el-col>
					<el-col :span="12" v-if="ideSkills.length">
						<el-card shadow="always">
							<div class="task-intro container">
								<h4 class="heading" style="text-align: center">
									My IDE Skills
								</h4>
							</div>
							<el-table :data="ideSkills" height="350" style="width: 100%">
								<el-table-column prop="tech" label="IDE" width="230" />
								<el-table-column prop="level" label="Skill Level" width="230" />
								<el-table-column label="Action" width="180" #default="scope">
									<el-button type="text" @click="EditSkillMatrix(scope)">
										<fa class="edit" :icon="['fas', 'edit']" />
									</el-button>
								</el-table-column>
							</el-table>
						</el-card>
					</el-col>
					<el-col :span="12" v-if="vtSkills.length">
						<el-card shadow="always">
							<div class="task-intro container">
								<h4 class="heading" style="text-align: center">
									My Version Tools Skills
								</h4>
							</div>
							<el-table :data="vtSkills" height="350" style="width: 100%">
								<el-table-column
									prop="tech"
									label="Version Tools"
									width="230"
								/>
								<el-table-column prop="level" label="Skill Level" width="230" />
								<el-table-column label="Action" width="180" #default="scope">
									<el-button type="text" @click="EditSkillMatrix(scope)">
										<fa class="edit" :icon="['fas', 'edit']" />
									</el-button>
								</el-table-column>
							</el-table>
						</el-card>
					</el-col>
					<el-col :span="12" v-if="osSkills.length">
						<el-card shadow="always">
							<div class="task-intro container">
								<h4 class="heading" style="text-align: center">My OS Skills</h4>
							</div>
							<el-table :data="osSkills" height="350" style="width: 100%">
								<el-table-column prop="tech" label="OS" width="230" />
								<el-table-column prop="level" label="Skill Level" width="230" />
								<el-table-column label="Action" width="180" #default="scope">
									<el-button type="text" @click="EditSkillMatrix(scope)">
										<fa class="edit" :icon="['fas', 'edit']" />
									</el-button>
								</el-table-column>
							</el-table>
						</el-card>
					</el-col>
					<el-col :span="12" v-if="webApiSkills.length">
						<el-card shadow="always">
							<div class="task-intro container">
								<h4 class="heading" style="text-align: center">
									My Web API Skills
								</h4>
							</div>
							<el-table :data="webApiSkills" height="350" style="width: 100%">
								<el-table-column prop="tech" label="OS" width="230" />
								<el-table-column prop="level" label="Skill Level" width="230" />
								<el-table-column label="Action" width="180" #default="scope">
									<el-button type="text" @click="EditSkillMatrix(scope)">
										<fa class="edit" :icon="['fas', 'edit']" />
									</el-button>
								</el-table-column>
							</el-table>
						</el-card>
					</el-col>
				</el-row>
				<div style="margin-bottom: 10%; margin-top: 5%" v-if="otherData">
					<label for="" style="font-size: initial"
						><b>Other skillset or certifications</b></label
					>
					<el-input
						v-model="otherData"
						maxlength="500"
						placeholder="Please add other skillset or certifications ex:Postman, Google Maps API, G Suite"
						show-word-limit
						type="textarea"
					/>
					<div class="action-btn" style="margin-top: 2%">
						<el-button
							style="width: 15%; float: right"
							class="btn submit-btn primary-bg color-white"
							native-type="submit"
							@click="__updateOtherDetail()"
							:loading="createTaskLoading"
						>
							Update Other Detail
						</el-button>
					</div>
				</div>
			</section>
		</div>
	</section>
	<el-drawer v-model="editDrawer" title="Update" direction="rtl">
		<el-form
			class="update-form"
			id="update-form"
			:model="updateSkill"
			:label-position="top"
			:rules="rules"
			:inline="true"
			label-width="100px"
			ref="updateSkill"
			@submit="$event.preventDefault()"
			@keypress.enter="__updateSkillMatrix($event)"
		>
			<el-form-item label="Skill">
				<el-select
					v-model="skillName"
					placeholder="Select Skill"
					style="width: 150%"
				>
					<el-option
						v-for="skill in skills"
						:key="skill"
						:label="skill.name"
						:value="skill.id"
					>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Skill Level">
				<el-select
					v-model="skillLevel"
					placeholder="Select Level"
					style="width: 150%"
				>
					<el-option
						v-for="level in skillLevels"
						:key="level"
						:label="level.level"
						:value="level.id"
					>
					</el-option>
				</el-select>
			</el-form-item>
			<div class="action-btn" style="margin-top: 5%">
				<el-button
					class="btn submit-btn primary-bg color-white"
					style="width: 40%; margin-left: 30%"
					native-type="submit"
					@click="__updateSkillMatrix($event)"
					:loading="createTaskLoading"
				>
					Update
				</el-button>
			</div>
		</el-form>
	</el-drawer>
</template>

<script>
	import TopNavbar from "@/components/TopNavbar.vue";
	import SkillMatrix from "../services/skillmatrix.js";

	export default {
		name: "Skill Matrix",

		data() {
			return {
				createTaskLoading: false,
				techSkills: [],
				fwSkills: [],
				dbSkills: [],
				ideSkills: [],
				vtSkills: [],
				osSkills: [],
				webApiSkills: [],
				navFlag: false,
				otherData: null,
				buttonText: "Create Skill Matrix",
				techStack: null,
				isEdit: false,
				selectedSkillMatrixData: {},
				profilePic: localStorage.getItem("profile_pic"),
				name: localStorage.getItem("username"),
				editDrawer: false,
				skillName: null,
				skillLevel: null,
				skills: [],
				skillLevels: [],
				isEmpty: false,
				techId: null,
				updateSkill: {
					tech_id: "",
					selected_tech_id: "",
					level: "",
					techStack: "",
				},
			};
		},

		components: {
			TopNavbar,
		},

		mounted() {
			this.empSkillMatrix();
		},

		methods: {
			empSkillMatrix() {
				SkillMatrix.getEmpSkillMatrix()
					.then((res) => {
						this.techSkills = res.data.technology;
						this.fwSkills = res.data.framework;
						this.dbSkills = res.data.database;
						this.ideSkills = res.data.ide;
						this.vtSkills = res.data.vt;
						this.osSkills = res.data.os;
						this.webApiSkills = res.data.webApi;
						this.otherData = res.data.other;
						console.log(this.techSkills.length);
						if (
							this.techSkills.length == 0 &&
							this.fwSkills.length == 0 &&
							this.dbSkills.length == 0 &&
							this.ideSkills.length == 0 &&
							this.vtSkills.length == 0 &&
							this.osSkills.length == 0 &&
							this.webApiSkills.length == 0 &&
							!this.otherData
						) {
							console.log("heheh");
							this.isEmpty = true;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},

			EditSkillMatrix(data) {
				this.isEdit = true;
				this.buttonText = "Update Skill Matrix";
				this.techId = data.row.techId;
				SkillMatrix.editSkillMatrix(data.row.matrixId)
					.then((res) => {
						this.editDrawer = !this.editDrawer;
						this.selectedSkillMatrixData = res.data.selected_skill_matrix;
						this.updateSkill.tech_id = data.row.techId;
						this.updateSkill.level = this.selectedSkillMatrixData.skill_level_id;
						this.skillName = data.row.tech;
						this.skillLevel = data.row.level;
						this.techStack = this.selectedSkillMatrixData.tech_stack;
						this.getAllTechs(this.techStack);
						this.getSkillLevels();
					})
					.catch((err) => {
						console.log(err);
					});
			},

			__updateSkillMatrix(ev) {
				console.log(ev);
				var data = [];
				if (typeof this.skillName === "string") {
					this.skillName = this.updateSkill.tech_id;
				}
				this.updateSkill = [
					{
						tech_id: this.updateSkill.tech_id,
						selected_tech_id: this.skillName,
						level: this.skillLevel,
						techStack: this.techStack,
					},
				];
				data = {
					matrixData: this.updateSkill,
				};
				SkillMatrix.updateSkillMatrixData(JSON.stringify(data))
					.then(() => {
						this.editDrawer = !this.editDrawer;
						this.empSkillMatrix();
						this.createTaskLoading = false;
						this.$notify({
							title: "Success",
							message: "Successfully updated the skill",
							type: "success",
							position: "bottom-right",
							duration: 2000,
						});

						setTimeout(() => {
							this.$router.push({ name: "Skill Matrix" });
						}, 2000);
					})
					.catch((err) => {
						console.log(err);
					});
			},

			__updateOtherDetail() {
				var data = {
					data: this.otherData,
				};
				SkillMatrix.updateOtherDetails(JSON.stringify(data))
					.then(() => {
						this.empSkillMatrix();
						this.createTaskLoading = false;
						this.$notify({
							title: "Success",
							message: "Successfully updated other detail",
							type: "success",
							position: "bottom-right",
							duration: 2000,
						});
					})
					.catch((err) => {
						console.log(err);
					});
			},

			getAllTechs(techStack) {
				SkillMatrix.getAllTechs()
					.then((res) => {
						if (techStack === "Technology") {
							this.skills = res.data.data.tech;
						} else if (techStack === "Framework") {
							this.skills = res.data.data.frameworks;
						} else if (techStack === "Database") {
							this.skills = res.data.data.dbs;
						} else if (techStack === "IDE") {
							this.skills = res.data.data.ide;
						} else if (techStack === "Version Tools") {
							this.skills = res.data.data.versionTools;
						} else if (techStack === "OS") {
							this.skills = res.data.data.operatingSystems;
						} else if (techStack === "WebAPI") {
							this.skills = res.data.data.webApis;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},

			getSkillLevels() {
				SkillMatrix.getAllSkillLevels()
					.then((res) => {
						this.skillLevels = res.data.all_levels;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			addSkills() {
				this.$router.push({ name: "Add Skill Matrix" });
			},
		},
	};
</script>


<style lang="scss" scoped>
	.container {
		margin-right: 100px;
		margin-left: 100px;
	}
</style>