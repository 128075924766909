<template>
	<div class="about">
		<h1>You are authorized</h1>
	</div>
</template>
<script>
	import SelfEvaluation from "../services/selfevaluation.js";
	export default {
		data() {
			return {
				project_id: null,
				authUrl: null,
			};
		},

		mounted() {
			this.authUrl = window.location.href;
			this.authCallback();
		},

		methods: {
			authCallback() {
				var data = JSON.stringify({
					url: this.authUrl,
				});
				SelfEvaluation.authorizeCallback(data)
					.then((res) => {
						console.log(res);
						this.$notify({
							title: "Success",
							message: "You are authorized successfully",
							type: "success",
							position: "bottom-right",
							duration: 2000,
						});
						setTimeout(() => {
							this.$router.push({ name: "Team Members" });
						}, 1000);
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},
	};
</script>