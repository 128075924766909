import http from ".././http-common.js";

export default {
    //to get all task of the clicked date from sidenav
    getQuestionList(userId) {
        return http.get(`/appraisal/api/questions/?user_id=${userId}`).then(res => {
            return res;
        });
    },

    getPointsMappingList() {
        return http.get("/appraisal/api/points_mapping/").then(res => {
            return res;
        });
    },

    // //to cretae new task. post req
    async storeSelfEvaluation(dataFinal, draft) {
        let userId = 0
        const res = await http.post(`/appraisal/api/emp_store_self_evaluation/?user_id=${userId}&draft=${draft}`, dataFinal);
        return res;
    },

    // //to cretae new task. post req
    async storeTeamEvaluation(dataFinal, userId, draft) {
        const res = await http.post(`/appraisal/api/emp_store_self_evaluation/?user_id=${userId}&draft=${draft}`, dataFinal);
        return res;
    },

    async authorizeCallback(data) {
        const res = await http.post(`/appraisal/api/callback/`, data);
        return res;
    },

    async managerFinalComment(data) {
        const res = await http.post(`/appraisal/api/manager/final-comment/`, data);
        return res;
    },

    fetchSelfEvaluationData(userId) {
        return http.get(`/appraisal/api/emp_fetch_self_evaluation/?user_id=${userId}`).then(res => {
            return res;
        });
    },

    fetchManagerEmp() {
        return http.get("/appraisal/api/manager/employees/").then(res => {
            return res;
        });
    },

    selfEvaulationStatus() {
        return http.get("/appraisal/api/appraisal_status").then(res => {
            return res;
        });
    },

    async meetingCompleted(finalData) {
        const res = await http.post(`/appraisal/api/manager/meeting-completed/`, finalData);
        return res;
    },

    // getAllSkillLevels(data) {
    //     return http.get("/timesheet/skillmat/api/all_skill_levels/", data).then(res => {
    //         return res;
    //     });
    // },
};
