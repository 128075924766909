import http from ".././http-common.js";

export default {
    //to get all task of the clicked date from sidenav
    teamMemberList() {
        return http.get("/appraisal/api/manager/employees/").then(res => {
            return res;
        });
    },

    filterTeamMembers(data) {
        return http.get(`/appraisal/api/filter/${data}/`).then(res => {
            return res;
        });
    },

    async authrorizationUrl() {
        return http.post("/appraisal/api/authorise/").then(res => {
            return res;
        });
    },

    async calendarInvitation(finalData) {
        const res = await http.post(`/appraisal/api/manager/cal-invite/`, finalData);
        return res;
    },

    async meetingCompleted(finalData) {
        const res = await http.post(`/appraisal/api/manager/meeting-completed/`, finalData);
        return res;
    },

    filteredTeamMemberList(data) {
        return http.get(`/appraisal/api/manager/employees?employee_name=${data}`).then(res => {
            return res;
        });
    },
};
