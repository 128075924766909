<template>
  <section>
    <header>
      <TopNavbar @clicked="sideNavEvent" :navFlag="navFlag" :name="username" :profile_pic="profilePic" :role="role" />
    </header>
  </section>

  <section>
    <div class="container">
      <h2 class="heading">Job Openings</h2>
      <div @click="handleMyReferrals($event)" class="my-referral-btn place-center">
        My Referrals
      </div>
    </div>

    <div class="opening-skelton" v-if="loading">
      <div v-for="index in 5" :key="index" class="opening-info">
        <el-skeleton :rows="1"></el-skeleton>
      </div>
    </div>

    <div v-if="isEmpty">
      <el-empty :image-size="200" />
    </div>
    <div class="table-container">
      <el-table v-if="!isEmpty && !loading" :data="openings" :table-layout="auto">
        <el-table-column prop="roles" label="Role" />
        <el-table-column prop="Responsibilities" label="Responsibilities" />
        <el-table-column label="Skills">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span>{{ scope.row.skills.join(", ") }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="location" label="Location" />
        <el-table-column prop="due_date" label="Due Date" />
        <el-table-column label="Operations">
          <template #default="scope">
            <button @click="handleRefer(scope.row.id, scope.row.roles)" class="refer-button">
              Refer Candidate
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
import TopNavbar from "@/components/TopNavbar.vue";
import JobOpenings from "../services/jobopenings.js";

export default {
  name: "Job Openings",

  data() {
    return {
      openings: [],
      isEmpty: false,
      profilePic: localStorage.getItem("profile_pic"),
      username: localStorage.getItem("username"),
      navFlag: false,
      loading: true,
      role: localStorage.getItem("role"),
    };
  },

  components: {
    TopNavbar,
  },

  mounted() {
    this.getAllJobOpenings();
  },

  methods: {
    getAllJobOpenings() {
      JobOpenings.getAllJobOpenings()
        .then((res) => {
          if (res?.data?.JobOpening_list?.length > 0) {
            this.openings = res.data.JobOpening_list;
            this.loading = false;
          } else {
            this.isEmpty = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.isEmpty = true;
          this.loading = false;
          console.log(err);
        });
    },
    handleRefer(id, role) {
      this.$router.push({
        name: "Refer Candidate",
        params: { referralId: id, role: role },
      });
    },
    handleMyReferrals() {
      this.$router.push({
        name: "MyReferrals",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 2rem;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.opening-skelton {
  padding: 12px;
}

.opening-info {
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 30px;
}

.heading {
  text-align: center;
}

.refer-button {
  background-color: rgb(105, 112, 180);
  border: none;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
}

.table-container {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-referral-btn {
  width: 150px;
  height: 35px;
  border: 1px solid $primary;
  cursor: pointer;
  margin: 18px;
  font-weight: 500;

  &:hover {
    background: $primary;
    color: #fff;
    margin: 18px;
  }
}
</style>
