import http from ".././http-common.js";

export default {
    //to get all task of the clicked date from sidenav
    getEmpSkillMatrix(data) {
        return http.get("/timesheet/skillmat/api/emp_skill_matrix/", data).then(res => {
            return res;
        });
    },

    editSkillMatrix(matrixId) {
        return http.get(`/timesheet/skillmat/api/edit_skill_matrix_data/?matrixId=${matrixId}`).then(res => {
            return res;
        });
    },

    getAllTechs() {
        return http.get("/timesheet/skillmat/api/all_technologies/").then(res => {
            return res;
        });
    },

    getAllSkillLevels(data) {
        return http.get("/timesheet/skillmat/api/all_skill_levels/", data).then(res => {
            return res;
        });
    },

    //to cretae new task. post req
    async storeSkillMatrix(data) {
        const res = await http.post("/timesheet/skillmat/api/store_skill_matrix/", data);
        return res;
    },

    async updateSkillMatrixData(data) {
        const res = await http.post("/timesheet/skillmat/api/update_skill_matrix/", data);
        return res;
    },

    async updateOtherDetails(data) {
        const res = await http.post("/timesheet/skillmat/api/update_other_details/", data);
        return res;
    },
    // //to update task
    // updateTask(data, taskId) {
    //     return http.put(`/timesheet/api/task/edit/${taskId}/`, data).then(res => {
    //         return res;
    //     });
    // },
    // //Delete task
    // deleteTask(taskId) {
    //     return http.delete(`/timesheet/api/task/edit/${taskId}/`).then(res => {
    //         return res;
    //     });
    // },

    // ProjectList() {
    //     let id = localStorage.getItem("P1");
    //     return http.get(`/timesheet/api/project/list/${id}/`).then(res => {
    //         return res;
    //     });
    // }
};
