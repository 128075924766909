<template>
  <section>
    <header>
      <TopNavbar :navFlag="navFlag" :name="username" :profile_pic="profilePic" :role="role" />
    </header>
  </section>

  <section>
    <div class="container">
      <h2 class="heading">Resources</h2>
      <div @click="handleAddNew($event)" class="new-referral-btn place-center">
        Add New
      </div>
    </div>

    <div class="opening-skelton" v-if="loading">
      <div v-for="index in 5" :key="index" class="opening-info">
        <el-skeleton :rows="1"></el-skeleton>
      </div>
    </div>

    <div v-if="isEmpty">
      <el-empty :image-size="200" />
    </div>
    <div v-if="!isEmpty && !loading" class="content-container">
      <el-table :data="resourceList" style="width: 100%">
        <el-table-column prop="roles" label="Role" width="180" />
        <el-table-column prop="job_description" label="Description" width="180" />
        <el-table-column label="Skills" width="180">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span>{{ scope.row.skills.join(", ") }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="resource_type" label="Resource type" width="180" />
        <el-table-column prop="experience_needed" label="Experience (in years)" width="180" />
        <el-table-column prop="due_date" label="Due date" width="180" />
        <el-table-column prop="number_of_position" label="Number of position" width="180" />
        <el-table-column prop="duration" label="Project duration (approx)" width="180" />
        <el-table-column prop="status_type" label="Status" width="180" />
        <el-table-column prop="location" label="Location" width="180" />
        <el-table-column label="Operations" width="180">
          <template #default="scope">
            <el-button type="text" @click="handleEditResource(scope.row)">
              <fa style="height: 20px" class="icon" :icon="['fas', 'edit']" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-drawer v-model="createResourceFlag" direction="rtl" size="60%" ref="drawer">
      <div class="new-referral-cover">
        <el-form class="referral-form" :model="resourceObj" :rules="rules" ref="resourceObj"
          @submit="$event.preventDefault()" @keypress.enter="createResource($event)">
          <div class="input-group">
            <label>Role</label>
            <el-form-item prop="role">
              <el-select style="display: block" v-model="resourceObj.role" placeholder="Select role">
                <el-option v-for="item in resourceRolesList" :key="item.id" :label="item.RoleName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Job Description</label>
            <el-form-item prop="description">
              <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" @keypress.enter.prevent
                placeholder="Please enter description" v-model="resourceObj.description">
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Skills</label>
            <el-form-item prop="skills">
              <el-select multiple style="display: block" v-model="resourceObj.skills" placeholder="Select skills">
                <el-option v-for="item in resourceSkillsList" :key="item.id" :label="item.TechName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Resource type</label>
            <el-form-item prop="resourceType">
              <el-radio-group v-model="resourceObj.resourceType">
                <el-radio :key="type.id" v-for="type in resourceTypeList" :label="type.name" />
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Experience Range (in year)</label>
            <el-form-item prop="experience">
              <el-input type="text" @keypress.enter.prevent placeholder="ex: 3.4 years or 5.6 years"
                v-model="resourceObj.experience">
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Due date</label>
            <el-form-item prop="dueDate">
              <el-date-picker v-model="resourceObj.dueDate" type="date" placeholder="Pick a date" style="width: 100%"
                :disabled-date="disabledDate" />
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Number of position</label>
            <el-form-item prop="numOfPosition">
              <el-input type="text" @keypress.enter.prevent placeholder="Enter number of position"
                v-model="resourceObj.numOfPosition">
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Location</label>
            <el-form-item prop="location">
              <el-select style="display: block" v-model="resourceObj.location" placeholder="Select location">
                <el-option v-for="item in locationList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Status</label>
            <el-form-item prop="status">
              <el-radio-group v-model="resourceObj.status">
                <el-radio :key="type.id" v-for="type in statusList" :label="type.name" />
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Project Duration</label>
            <el-form-item prop="duration">
              <el-input type="text" @keypress.enter.prevent placeholder="Enter duration" v-model="resourceObj.duration">
              </el-input>
            </el-form-item>
          </div>

          <div class="action-btn">
            <button class="btn close-btn color-black" @click="this.createResourceFlag = false">
              Close
            </button>
            <el-button class="btn submit-btn primary-bg color-white" native-type="submit" @click="createResource($event)"
              :loading="createResourceLoading">
              Submit
            </el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
    <el-drawer v-model="editResourceFlag" direction="rtl" size="60%" ref="drawer">
      <div class="new-referral-cover">
        <el-form class="referral-form" :model="resourceObj" :rules="rules" ref="resourceObj"
          @submit="$event.preventDefault()" @keypress.enter="editResource($event)">
          <div class="input-group">
            <label>Role</label>
            <el-form-item prop="role">
              <el-select style="display: block" v-model="resourceObj.role" placeholder="Select role">
                <el-option v-for="item in resourceRolesList" :key="item.id" :label="item.RoleName" :value="item.RoleName">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Job Description</label>
            <el-form-item prop="description">
              <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" @keypress.enter.prevent
                placeholder="Please enter description" v-model="resourceObj.description">
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Skills</label>
            <el-form-item prop="skills">
              <el-select multiple style="display: block" v-model="resourceObj.skills" placeholder="Select skills">
                <el-option v-for="item in resourceSkillsList" :key="item.id" :label="item.TechName"
                  :value="item.TechName">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Resource type</label>
            <el-form-item prop="resourceType">
              <el-radio-group v-model="resourceObj.resourceType">
                <el-radio :key="type.id" v-for="type in resourceTypeList" :label="type.name" />
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Experience Range (in year)</label>
            <el-form-item prop="experience">
              <el-input type="text" @keypress.enter.prevent placeholder="ex: 3.4 years or 5.6 years"
                v-model="resourceObj.experience">
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Due date</label>
            <el-form-item prop="dueDate">
              <el-date-picker v-model="resourceObj.dueDate" type="date" placeholder="Pick a date" style="width: 100%"
                :disabled-date="disabledDate" />
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Number of position</label>
            <el-form-item prop="numOfPosition">
              <el-input type="text" @keypress.enter.prevent placeholder="Enter number of position"
                v-model="resourceObj.numOfPosition">
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Location</label>
            <el-form-item prop="location">
              <el-select style="display: block" v-model="resourceObj.location" placeholder="Select location">
                <el-option v-for="item in locationList" :key="item.id" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Status</label>
            <el-form-item prop="status">
              <el-radio-group v-model="resourceObj.status">
                <el-radio :key="type.id" v-for="type in statusList" :label="type.name" />
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Project Duration</label>
            <el-form-item prop="duration">
              <el-input type="text" @keypress.enter.prevent placeholder="Enter duration" v-model="resourceObj.duration">
              </el-input>
            </el-form-item>
          </div>

          <div class="action-btn">
            <button class="btn close-btn color-black" @click="this.editResourceFlag = false">
              Close
            </button>
            <el-button class="btn submit-btn primary-bg color-white" native-type="submit" @click="editResource($event)"
              :loading="editResourceLoading">
              Update
            </el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import TopNavbar from "@/components/TopNavbar.vue";
import moment from "moment";
import JobReferrings from "../services/jobreferrings.js";

export default {
  name: "Resources",

  data() {
    return {
      resourceList: [],
      isEmpty: false,
      profilePic: localStorage.getItem("profile_pic"),
      username: localStorage.getItem("username"),
      navFlag: false,
      loading: true,
      createResourceFlag: false,
      createResourceLoading: false,
      resourceTypeList: [],
      resourceRolesList: [],
      resourceSkillsList: [],
      locationList: [],
      statusList: [],
      typeId: "",
      statusId: "",
      roleId: "",
      locationId: "",
      resourceObj: {
        role: "",
        description: "",
        skills: "",
        resourceType: "",
        experience: "",
        dueDate: "",
        numOfPosition: "",
        duration: "",
        location: "",
        status: "",
      },
      role: localStorage.getItem("role"),
      resourceId: "",
      editResourceFlag: false,
      editResourceLoading: false,
      rules: {
        role: [
          {
            required: true,
            message: "role is required.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "description is required.",
            trigger: "blur",
          },
        ],
        skills: [
          {
            required: true,
            message: "skills is required.",
            trigger: "blur",
          },
        ],
        resourceType: [
          {
            required: true,
            message: "resource type is required.",
            trigger: "blur",
          },
        ],
        experience: [
          {
            required: true,
            message: "experience is required.",
            trigger: "blur",
          },
        ],
        dueDate: [
          {
            required: true,
            message: "due date is required.",
            trigger: "blur",
          },
        ],
        numOfPosition: [
          {
            required: true,
            message: "number of position is required.",
            trigger: "blur",
          },
        ],
        duration: [
          {
            required: true,
            message: "duration is required.",
            trigger: "blur",
          },
        ],
        location: [
          {
            required: true,
            message: "location is required.",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "status is required.",
            trigger: "blur",
          },
        ],
      },
    };
  },

  components: {
    TopNavbar,
  },

  mounted() {
    this.getAllResources();
    this.getResourcesRoleTypes();
  },

  methods: {
    getAllResources() {
      this.resourceList = [];
      this.loading = true;
      JobReferrings.getAllResources()
        .then((res) => {
          if (res?.data?.Resources_list?.length > 0) {
            this.resourceList = res.data.Resources_list;
            this.loading = false;
          } else {
            this.isEmpty = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getResourcesRoleTypes() {
      JobReferrings.getResourcesRoleTypes()
        .then((res) => {
          if (res?.data?.Resource_Type?.length > 0) {
            this.resourceTypeList = res.data.Resource_Type;
          }

          if (res?.data?.Role?.length > 0) {
            this.resourceRolesList = res.data.Role;
          }

          if (res?.data?.Skills?.length > 0) {
            this.resourceSkillsList = res.data.Skills;
          }

          if (res?.data?.location?.length > 0) {
            this.locationList = res.data.location;
          }

          if (res?.data?.status?.length > 0) {
            this.statusList = res.data.status;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createResource() {
      this.$refs["resourceObj"].validate((valid) => {
        if (valid && !this.createResourceLoading) {
          this.createResourceLoading = true;
          this.resourceTypeList.map((type) => {
            if (type.name === this.resourceObj.resourceType) {
              this.typeId = type.id;
            }
          });
          this.statusList.map((item) => {
            if (item.name === this.resourceObj.status) {
              this.statusId = item.id;
            }
          });
          this.data = {
            roles: this.resourceObj.role,
            job_description: this.resourceObj.description,
            skills: this.resourceObj.skills,
            experience_needed: this.resourceObj.experience,
            due_date: moment(this.resourceObj.dueDate).format("YYYY-MM-DD"),
            duration: this.resourceObj.duration,
            number_of_position: this.resourceObj.numOfPosition,
            Resource_type: this.typeId,
            status: this.statusId,
            location: this.resourceObj.location,
          };
          JobReferrings.createResource(this.data)
            .then(() => {
              this.resourceObj = {};
              this.createResourceLoading = false;
              this.createResourceFlag = false;
              this.resetmodal();
              this.getAllResources();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return;
        }
      });
    },
    resetmodal() {
      this.$refs["resourceObj"].resetFields();
    },
    disabledDate(time) {
      const date = new Date();
      const previousDate = date.setDate(date.getDate() - 1);
      return time.getTime() < previousDate;
    },
    handleEditResource(resource) {
      this.editResourceFlag = true;
      this.resourceId = resource.id;
      this.resourceObj = {
        role: resource.roles,
        description: resource.job_description,
        skills: resource.skills,
        resourceType: resource.resource_type,
        experience: resource.experience_needed,
        dueDate: resource.due_date,
        numOfPosition: resource.number_of_position,
        duration: resource.duration,
        location: resource.location,
        status: resource.status_type
      }
    },
    handleAddNew() {
      this.resourceObj = {};
      this.createResourceFlag = true;
    },
    editResource() {
      this.$refs["resourceObj"].validate((valid) => {
        this.skillsIdArray = [];
        if (valid && !this.editResourceLoading) {
          this.editResourceLoading = true;
          this.resourceRolesList.map((role) => {
            if (role.RoleName === this.resourceObj.role) {
              this.roleId = role.id;
            }
          });
          this.resourceTypeList.map((type) => {
            if (type.name === this.resourceObj.resourceType) {
              this.typeId = type.id;
            }
          });
          this.resourceSkillsList.map((skill) => {
            if (this.resourceObj.skills.includes(skill.TechName)) {
              this.skillsIdArray.push(skill.id);
            }
          });
          this.statusList.map((status) => {
            if (status.name === this.resourceObj.status) {
              this.statusId = status.id;
            }
          });
          this.locationList.map((location) => {
            if (location.name === this.resourceObj.location) {
              this.locationId = location.id;
            }
          });
          this.data = {
            roles: this.roleId,
            job_description: this.resourceObj.description,
            skills: this.skillsIdArray,
            experience_needed: this.resourceObj.experience,
            due_date: moment(this.resourceObj.dueDate).format("YYYY-MM-DD"),
            duration: this.resourceObj.duration,
            number_of_position: this.resourceObj.numOfPosition,
            Resource_type: this.typeId,
            location: this.locationId,
            status: this.statusId,
          };
          JobReferrings.editResource(this.resourceId, this.data)
            .then(() => {
              this.editResourceLoading = false;
              this.editResourceFlag = false;
              this.resetmodal();
              this.getAllResources();
            })
            .catch((err) => {
              this.editResourceLoading = false;
              console.log(err);
            });
        } else {
          return;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 2rem;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.opening-skelton {
  padding: 12px;
}

.opening-info {
  margin-bottom: 30px;
}

.heading {
  text-align: center;
}

.content-container {
  margin: 2rem;
}

.new-referral-btn {
  width: 150px;
  height: 35px;
  border: 1px solid $primary;
  cursor: pointer;
  margin: 18px;
  font-weight: 500;

  &:hover {
    background: $primary;
    color: #fff;
    margin: 18px;
  }
}

.bold-text {
  font-size: 15px;
  font-weight: bold;
}

.drawer-title {
  font-size: 22px;
}

label {
  font-size: 15px;
}

.new-referral-cover {
  width: 600px;
  margin: auto;

  @media (max-width: 960px) {
    width: 540px;
    display: grid;
    place-content: left;
    margin: auto;
  }

  .action-btn {
    display: flex;
    padding: 20px;
    justify-content: space-around;

    .submit-btn,
    .close-btn {
      width: 35%;
      margin-right: 10px;
    }
  }
}

.new-referral-btn {
  width: 150px;
  height: 35px;
  border: 1px solid $primary;
  cursor: pointer;
  margin: 18px;
  font-weight: 500;

  &:hover {
    background: $primary;
    color: #fff;
    margin: 18px;
  }
}
</style>
