<template>
  <nav class="top-nav-bar primary-bg">
    <span class="page-header">
      <fa
        class="hamburger"
        :icon="['fas', 'bars']"
        @click="onClickHamburger(navFlag)"
      />
      <img
        class="logo"
        src="@/assets/images/icon.png"
        alt="pacewisdom"
        width="50"
      />
      <span class="page-title"
        ><router-link to="/home" style="text_decoration: none; color: white"
          >Project Time Tracker
        </router-link></span
      >
    </span>

    <!-- <div class="collapse navbar-collapse" id="navbarNavDropdown">
			<ul class="navbar-nav custom-admin-nav">
				<li class="nav-item">
					<a class="nav-link"
						><router-link to="/skill-matrix">Skill Matrix</router-link></a
					>
				</li>
			</ul>
		</div> -->

    <a>
      <span v-if="role === 'manager' || roleName === 'manager'" class="username">
        <router-link to="/resources" style="color: white"
          >Resources</router-link
        >
      </span>
      <span class="username">
        <router-link to="/job-openings" style="color: white"
          >Openings</router-link
        >
      </span>
      <span class="username"
        ><router-link
          to="/home"
          :class="[
            isActive && 'router-link-active',
            isExactActive && 'router-link-exact-active',
          ]"
          style="color: white"
          >Timesheet</router-link
        ></span
      >
      <span class="username" @click="skillMatrix"
        ><router-link to="/skills" style="color: white"
          >Skills</router-link
        ></span
      >
      <span class="username"
        ><router-link to="/view-project-documents" style="color: white"
          >Documents</router-link
        ></span
      >
      <span class="username"
        ><router-link to="/appraisal" style="color: white"
          >Appraisal</router-link
        ></span
      >
      <span class="username">
        <a
          href="https://docs.google.com/document/d/1s_ryTwuS4YNDXrK5mkVUq7ghCjNF087Py3IWct_UI_g/edit?usp=sharing"
          target="_blank"
          style="color: white"
          >QMS & ISMS Policy</a
        >
      </span>

      <el-popover
        :width="300"
        popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
      >
        <template #reference>
          <span class="username" style="vertical-align: middle">
            <el-avatar :size="40" :src="profile_pic"></el-avatar>
          </span>
        </template>
        <template #default>
          <el-dropdown :ssize="large">
            <el-dropdown-menu>
              <el-dropdown-item
                ><fa :icon="['fas', 'user']" /> {{ name }}</el-dropdown-item
              >
              <el-dropdown-item @click="profile"
                ><fa :icon="['fas', 'user']" /> Profile</el-dropdown-item
              >
              <el-dropdown-item @click="centerDialogVisible = true"
                ><fa :icon="['fas', 'sign-out-alt']" /> Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-popover>
      <el-dialog
        v-model="centerDialogVisible"
        title="Are you sure"
        width="30%"
        center
      >
        <span> Are you sure you want to logout </span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="centerDialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="logout"> Logout </el-button>
          </span>
        </template>
      </el-dialog>
    </a>
  </nav>
</template>

<script>
export default {
  name: "TopNavbar",
  props: ["navFlag", "name", "profile_pic", "role"],
  data() {
    return {
      username: "",
      loggedInUserName: localStorage.getItem("username"),
      profilePic: localStorage.getItem("profile_pic"),
      centerDialogVisible: false,
      roleName: localStorage.getItem("role"),
    };
  },

  methods: {
    onClickHamburger(navFlag) {
      this.$emit("clicked", !navFlag);
    },

    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },

    profile() {
      this.$router.push("/profile");
    },

    skillMatrix() {},
  },
};
</script>

<style lang="scss" scoped>
.top-nav-bar {
  // background: red;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;

  .heading {
    color: #fff;
  }

  .page-header {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      @media (max-width: 960px) {
        width: 25px;
        padding: 4px;
      }
    }
    .hamburger {
      @media (min-width: 960px) {
        display: none;
      }
    }
  }
  .username {
    color: #fff;
    font-size: 18px;
    margin-right: 1rem;

    @media (max-width: 960px) {
      display: none;
    }
  }

  .logout {
    color: #fff;
    font-size: 18px;
    margin-right: 1rem;
    cursor: pointer;
  }

  .page-title {
    margin-left: 1rem;
    font-family: $font;
    @media (max-width: 960px) {
      font-size: 15px;
      margin-left: 2px;
    }
  }

  .active {
    text-decoration: revert !important;
  }
}
</style>
