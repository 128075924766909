<template>
	<section class="home-page">
		<header class="header">
			<TopNavbar
				@clicked="sideNavEvent"
				:navFlag="navFlag"
				:name="name"
                :role="role"
				:profile_pic="profilePic"
			/>
		</header>
	</section>
	<section class="team-task-tab">
		<div class="container">
			<div class="task-intro container">
				<h2 class="heading" style="text-align: center">Documents</h2>
				<el-divider></el-divider>
			</div>
			<!-- CARDS SKELTON -->
			<div class="loading-skelton" v-if="loading">
				<el-skeleton :loading="loading" animated>
					<template #template>
						<el-carousel height="150px" style="overflow: hidden" arrow="never">
							<div class="cards">
								<div v-for="index in 8" :key="index">
									<div
										:class="{ active: activeTabIndex === index }"
										class="card card-1"
									>
										<fa class="project-icon" :icon="['fas', 'tasks']" />
										<!-- <el-svg-icon> <document-copy /> </el-svg-icon> -->
										<el-skeleton-item class="card__title"> </el-skeleton-item>
										<el-skeleton-item class="card__title"> </el-skeleton-item>
									</div>
								</div>
							</div>
						</el-carousel>
					</template>
				</el-skeleton>
			</div>
			<h2>{{ typeOfProjects }}</h2>
			<div style="float: right; margin-bottom: 10px" v-if="role === 'manager'">
				<el-button type="primary" icon="el-icon-time" @click="oldProjectDoc"
					>Archived Projects</el-button
				>
				<el-button type="primary" icon="el-icon-check" @click="getProjects"
					>Active Projects</el-button
				>
			</div>
			<el-input
				placeholder="Type something"
				prefix-icon="el-icon-search"
				v-model="search_project"
				@keyup="searchProject"
			>
			</el-input>

			<!-- cards -->
			<el-carousel
				v-if="!loading"
				height="150px"
				style="overflow: hidden"
				arrow="never"
			>
				<button @click="slideLeft" class="left">
					<i class="el-icon-caret-left"></i>
				</button>
				<button class="right" @click="sliderRight">
					<i class="el-icon-caret-right"></i>
				</button>
				<div class="cards">
					<div v-for="(item, index) in projects" :key="index">
						<div
							:class="{ active: activeTabIndex === index }"
							class="card card-1"
							@click="activeTab(index, item)"
						>
							<fa class="project-icon" :icon="['fas', 'tasks']" />
							<!-- <el-svg-icon> <document-copy /> </el-svg-icon> -->
							<h2 class="card__title">
								{{ item.name }}
							</h2>
						</div>
					</div>
				</div>
			</el-carousel>

			<el-table :data="tableData" style="width: 100%">
				<el-table-column label="Document Name" prop="doc_name">
				</el-table-column>
				<el-table-column label="File">
					<template v-slot:default="table">
						<a :href="table.row.file" target="_blank">{{ table.row.file }}</a>
					</template>
				</el-table-column>
				<el-table-column label="Comments" prop="comments"> </el-table-column>
			</el-table>
		</div>
	</section>
</template>

<script>
	import TopNavbar from "@/components/TopNavbar.vue";
	import moment from "moment";
	import Documents from "../services/documents";
	export default {
		data() {
			return {
				project_id: null,
				activeTabIndex: 0,
				filterFlag: false,
				value: [],
				value2: [],
				list: [],
				loading: true,
				projects: [],
				resources: [],
				tableData: [],
				search_project: "",
				toSearchProjects: [],
				typeOfProjects: "Active Projects",
				role: null,
				history: false,
				profilePic: localStorage.getItem("profile_pic"),
				name: localStorage.getItem("username"),
			};
		},

		components: {
			TopNavbar,
		},

		created: function () {
			this.moment = moment;
			this.role = localStorage.getItem("role");
		},

		computed: {
			getNumbers() {
				return this.$store.getters.getNumbers;
			},
		},

		mounted() {
			this.getProjects();
		},
		watch: {
			project_id: function () {
				this.resources = [];
				this.value2 = null;
			},
		},

		methods: {
			getDocsOfProject(projId) {
				this.tableData = [];
				Documents.DocsOfProject(projId).then((res) => {
					this.tableData = res.data.doc_files;
				});
			},

			activeTab(index, item) {
				this.activeTabIndex = index;
				this.project_id = item.id;
				this.getDocsOfProject(item.id);
			},

			getProjects() {
				this.typeOfProjects = "Active Projects";
				this.history = false;
				this.loading = true;
				Documents.ProjectList().then((res) => {
					this.tableData = [];
					this.projects = res.data.project_list;
					this.toSearchProjects = res.data.project_list;
					this.loading = false;
				});
			},

			oldProjectDoc() {
				this.typeOfProjects = "Archived Projects";
				this.history = true;
				this.loading = true;
				Documents.HistoryProjectList().then((res) => {
					this.tableData = [];
					this.projects = res.data.project_list;
					this.toSearchProjects = res.data.project_list;
					this.loading = false;
				});
			},

			slideLeft() {
				document.querySelector(".cards").scrollLeft -= 300;
			},

			sliderRight() {
				document.querySelector(".cards").scrollLeft += 300;
			},

			searchProject() {
				if (this.search_project) {
					let searchedProjects = [];
					this.toSearchProjects.forEach((project) => {
						let projectName = project.name.toLowerCase();
						if (projectName.includes(this.search_project.toLowerCase())) {
							searchedProjects.push({ id: project.id, name: project.name });
						}
					});
					this.projects = [];
					this.projects = searchedProjects;
				} else {
					if (this.history) {
						this.oldProjectDoc();
					} else {
						this.getProjects();
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.team-task-tab {
		.left {
			border: none;
			outline: 0;
			padding: 0;
			margin: 0;
			height: 36px;
			width: 36px;
			cursor: pointer;
			transition: 0.3s;
			border-radius: 50%;
			background-color: rgba(31, 45, 61, 0.11);
			color: #fff;
			position: absolute;
			top: 50%;
			z-index: 10;
			transform: translateY(-50%);
			text-align: center;
			font-size: 12px;
		}
		.right {
			right: 2px;
			border: none;
			outline: 0;
			padding: 0;
			margin: 0;
			height: 36px;
			width: 36px;
			cursor: pointer;
			transition: 0.3s;
			border-radius: 50%;
			background-color: rgba(31, 45, 61, 0.11);
			color: #fff;
			position: absolute;
			top: 50%;
			z-index: 10;
			transform: translateY(-50%);
			text-align: center;
			font-size: 12px;
		}
		.cards {
			// display: flex;
			// flex-wrap: wrap;
			// justify-content: space-between;
			display: grid;
			overflow: hidden;
			grid-auto-flow: column;
			grid-auto-columns: minmax(240px, 1fr);
			scroll-behavior: smooth;
		}
		.card {
			background-color: white;
			margin: 20px;
			padding: 20px;
			width: 150px;
			min-height: 70px;
			// display: grid;
			// grid-template-rows: 20px 50px 1fr 50px;
			border-radius: 10px;
			box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
			transition: all 0.2s;
			cursor: pointer;
		}

		.active {
			box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
			transform: scale(1.01);
			background-color: #474c7e;
			color: white;
			.card__title {
				color: white;
			}
			.project-icon {
				color: white;
			}
		}

		.card__title {
			grid-row: 3/4;
			font-weight: 900;
			color: #474c7e;
			font-size: 12px;
		}
		.project-icon {
			font-weight: 900;
			color: #474c7e;
			font-size: 12px;
		}

		/* CARD BACKGROUNDS */

		// .card-1 {
		//   //background: white;
		// }

		.filter {
			text-align: right;
			padding: 10px 0 10px 0;

			.fade-enter-active,
			.fade-leave-active {
				transition: opacity 0.5s ease;
			}

			.fade-enter-from,
			.fade-leave-to {
				opacity: 0;
			}

			.filter-button {
				margin-left: 10px;
			}

			.filter-options {
				padding: 10px 0 10px 0;
				display: inline;
			}
			.select_tag {
				width: 40%;
				padding-left: 5px;
			}
		}
		.accept-button {
			color: #43a784;
			font-size: 16px;
			background: #edfcf5;
			border: none;
			cursor: auto;
		}
		.approved-button {
			color: #afadad;
			font-size: 14px;
			background: #ede8e8;
			border: none;
			cursor: not-allowed;
		}
		.reject-button {
			color: #de0654;
			font-size: 16px;
			background: #ffe9f0;
			border: none;
		}
		.expand-det {
			border: 12px solid red;
		}

		.ongoing {
			color: white;
			background-color: #2ebdc9;
			border: none;
			// font-weight: 900;
		}

		.done {
			color: white;
			background-color: lightgreen;
			border: none;
			cursor: pointer;
		}
		.pending {
			color: white;
			background: #ffdc3d;
			border: none;
			// font-weight: 900;
			cursor: pointer;
			font-size: 14px;
		}

		.task_desc_expanded {
			letter-spacing: 1px;
		}

		.container {
			margin-right: 100px;
			margin-left: 100px;
		}
	}
</style>
