<template>
  <section class="refer-candidate">
    <header class="header">
      <TopNavbar @clicked="sideNavEvent" :navFlag="navFlag" :name="name" :profile_pic="profilePic" :role="role" />
    </header>
  </section>
  <section class="referral-list">
    <div class="sub-heading">
      <h2 class="heading">My Referrals</h2>
    </div>

    <div class="data-container">
      <div v-if="isEmpty">
        <el-empty :image-size="200" description="No Referrals found" />
      </div>
    </div>

    <div class="opening-skelton" v-if="loading">
      <div v-for="index in 5" :key="index" class="opening-info">
        <el-skeleton :rows="1"></el-skeleton>
      </div>
    </div>

    <div v-if="!loading && !isEmpty">
      <el-table :data="myReferralList" style="width: 100%">
        <el-table-column prop="job_opening" label="Role" width="180" />
        <el-table-column prop="job_description" label="Job Description" width="180" />
        <el-table-column prop="referee_name" label="Referee Name" width="180" />
        <el-table-column prop="referee_email" label="Referee Email" width="180" />
        <el-table-column prop="referee_phone" label="Referee Phone Number" width="180" />
        <el-table-column label="Current Company/Position" width="180">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span>{{
                scope.row.company_name + ", " + scope.row.company_position
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="experience" label="Experience of the candidate range (in year)" width="180" />
        <el-table-column prop="notice_period" label="Notice period of the candidate (in days)" width="180" />
        <el-table-column prop="relationship" label="Relationship with the candidate" width="180" />
        <el-table-column prop="referral_status" label="Referral Status" width="180" />
        <el-table-column label="Resume/CV" width="180">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <a target="_blank" :href="scope.row.resume">
                <span class="value">{{ getFileName(scope.row.resume) }}</span><br />
              </a>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Comment" width="180"> <template #default="scope">
            <div style="display: flex; align-items: center">
              <span>{{ scope.row.note && scope.row.note !== "undefined" ? scope.row.note : "" }}</span>
            </div>
          </template></el-table-column>
        <el-table-column label="Operations" width="180">
          <template #default="scope">
            <el-button type="text" @click="handleEditReferral(scope.row.id)">
              <fa style="height: 20px" class="icon" :icon="['fas', 'edit']" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-drawer v-model="editReferralDialogue" direction="rtl" custom-class="demo-drawer" size="60%" ref="drawer">
      <div class="new-referral-cover">
        <el-form class="referral-form" :model="referralObj" :rules="rules" ref="referralObj"
          @submit="$event.preventDefault()" @keypress.enter="editReferral()">
          <div>
            <p class="drawer-title">Filled details for referral</p>
          </div>

          <div class="input-group">
            <label>Referee Name</label>
            <el-form-item prop="name">
              <el-input type="text" v-model="referralObj.name" @keypress.enter.prevent>
              </el-input>
            </el-form-item>
          </div>
          <div class="input-group">
            <label>Referee Email</label>
            <el-form-item prop="email">
              <el-input type="text" v-model="referralObj.email" @keypress.enter.prevent>
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Referee Phone Number</label>
            <el-form-item prop="phone">
              <el-input type="text" v-model="referralObj.phone" @keypress.enter.prevent>
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Current Company/Position</label>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="referralCompanyName">
                  <el-input type="text" @keypress.enter.prevent placeholder="Current Company name"
                    v-model="referralObj.referralCompanyName">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="companyPosition">
                  <el-input type="text" @keypress.enter.prevent placeholder="Current position"
                    v-model="referralObj.companyPosition">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <div class="input-group">
            <label>Experience of the candidate range (in year)</label>
            <el-form-item prop="exp">
              <el-input type="text" v-model="referralObj.exp" @keypress.enter.prevent>
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Notice period of the candidate (in days)</label>
            <el-form-item prop="notice">
              <el-input type="text" v-model="referralObj.notice" @keypress.enter.prevent>
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Relation with the candidate</label>
            <el-form-item prop="relation">
              <el-input type="text" v-model="referralObj.relation" @keypress.enter.prevent>
              </el-input>
            </el-form-item>
          </div>

          <div class="input-group">
            <label>Resume/CV</label>
            <el-upload :accept="allowedFileTypes" :on-remove="handleOnRemove" :on-change="handleOnChange" :limit=1
              :auto-upload="false" ref="file" action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
              class="upload-area" drag>
              <div class="block2">
                <div>
                  <img width="50" height="60" src="../assets/images/upload.svg" />
                  <p class="bold-text">Browse Files</p>
                </div>
                <div class="el-upload__text">
                  <p>Drag and drop files here</p>
                </div>
              </div>
            </el-upload>
          </div>

          <div class="input-group">
            <label>Comments</label>
            <el-form-item prop="note">
              <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="Enter Comment"
                v-model="referralObj.note" maxlength="256" show-word-limit>
              </el-input>
            </el-form-item>
          </div>

          <div class="action-btn">
            <button class="btn close-btn color-black" @click="editReferralDialogue = false">
              Close
            </button>
            <el-button class="btn submit-btn primary-bg color-white" native-type="submit" :loading="editReferralLoading"
              @click="editReferral()">
              Update
            </el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import refercandidate from '../services/refercandidate';
import TopNavbar from "@/components/TopNavbar.vue";


export default {
  name: "MyReferrals",
  components: {
    TopNavbar,
  },
  data() {
    return {
      myReferralList: [],
      isEmpty: false,
      profilePic: localStorage.getItem("profile_pic"),
      username: localStorage.getItem("username"),
      navFlag: false,
      role: localStorage.getItem("role"),
      loading: true,
      editReferralDialogue: false,
      referralId: "",
      referralObj: {},
      allowedFileTypes: 'application/pdf, application/msword, text/plain',
      file: '',
      editReferralLoading: false,

      rules: {
        name: [
          {
            required: true,
            message: "name is required.",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "email is required.",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "phone number is required.",
            trigger: "blur",
          },
        ],
        referralCompanyName: [
          {
            required: true,
            message: "company name is required.",
            trigger: "blur",
          },
        ],
        companyPosition: [
          {
            required: true,
            message: "current position is required.",
            trigger: "blur",
          },
        ],
        exp: [
          {
            required: true,
            message: "candidate experience is required.",
            trigger: "blur",
          },
        ],
        notice: [
          {
            required: true,
            message: "candidate notice period is required.",
            trigger: "blur",
          },
        ],
        relation: [
          {
            required: true,
            message: "candidate relation is required.",
            trigger: "blur",
          },
        ]

      },
    };
  },
  mounted() {
    this.getMyReferralList();
  },
  methods: {
    handleEditReferral(id) {
      this.referralId = id;
      this.editReferralDialogue = true;
      refercandidate.getReferralDetails(id)
        .then((res) => {
          const referralData = res.data.Referral_list[0];
          this.referralObj.name = referralData.referee_name;
          this.referralObj.email = referralData.referee_email;
          this.referralObj.phone = referralData.referee_phone;
          this.referralObj.referralCompanyName = referralData.company_name;
          this.referralObj.companyPosition = referralData.company_position;
          this.referralObj.exp = referralData.experience;
          this.referralObj.notice = referralData.notice_period;
          this.referralObj.relation = referralData.relationship;
          this.referralObj.resume = res.data.resume_url;
          this.referralObj.note = referralData.note;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editReferral() {
      this.$refs["referralObj"].validate((valid) => {
        if (valid && !this.editReferralLoading) {
          this.editReferralLoading = true;
          const fd = new FormData();
          if (this.file && this.file !== "" && this.file !== null) {
            fd.append('resume', this.file.raw, this.file.name);
          } else {
            fd.append('resume', '');
          }
          fd.append('referee_name', this.referralObj.name);
          fd.append('referee_email', this.referralObj.email);
          fd.append('referee_phone', this.referralObj.phone);
          fd.append('company_name', this.referralObj.referralCompanyName);
          fd.append('company_position', this.referralObj.companyPosition);
          fd.append('experience', this.referralObj.exp);
          fd.append('notice_period', this.referralObj.notice);
          fd.append('relationship', this.referralObj.relation);
          fd.append('note', this.referralObj.note);
          refercandidate.editReferral(this.referralId, fd)
            .then(() => {
              this.editReferralLoading = false;
              this.editReferralDialogue = false;
              this.resetmodal();
              this.getMyReferralList();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return;
        }
      });
    },
    handleOnChange(file) {
      this.file = file;
    },
    handleOnRemove() {
      this.file = "";
    },
    resetmodal() {
      this.$refs["referralObj"].resetFields();
    },
    getMyReferralList() {
      refercandidate.getMyReferralList()
        .then((res) => {
          if (res?.data?.Referral_list?.length > 0) {
            this.isEmpty = false;
            this.myReferralList = res.data.Referral_list;
          } else {
            this.isEmpty = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.isEmpty = true;
          this.loading = false;
          console.log(err);
        });
    },
    getFileName(url) {
      const regex = /\/([^/?]+)(?:\?|$)/;
      const matches = regex.exec(url);
      return matches ? matches[1] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-list {
  overflow: hidden;
  margin-left: 2rem;
  margin-right: 2rem;

  .sub-heading {
    flex-direction: row;
    display: flex;
    justify-content: center;
    border-bottom-color: black;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .heading {
    text-align: center;
  }

  a,
  a:visited,
  a:hover,
  a:active {
    color: blue;
  }
}

.new-referral-cover {
  width: 500px;
  margin: auto;

  @media (max-width: 960px) {
    width: 340px;
    display: grid;
    place-content: center;
    margin: auto;
  }

  .action-btn {
    display: flex;
    padding: 20px;
    justify-content: space-around;

    .submit-btn,
    .close-btn {
      width: 45%;
      margin-right: 10px;
    }
  }
}
</style>
