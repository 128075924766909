<template>
	<div class="task-intro container">
		<h4 class="heading" style="text-align: left">Operating System</h4>
	</div>
	<div
		v-for="(input, index) in osData"
		:key="`matrixInput-${index}`"
		class="input wrapper"
		style="display: flex"
	>
		<!-- Technology  -->
		<p style="margin-right: 2%">Operating System: {{ ++index }}</p>
		<el-form-item prop="tech_id" style="margin-right: 20%">
			<el-select
				style="width: 200%"
				v-model="input.os_id"
				placeholder="Select Operating System"
			>
				<el-option
					v-for="os in allOs"
					:key="os"
					:label="os.name"
					:value="os.id"
				>
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item prop="level" label="">
			<el-select
				style="width: 200%"
				v-model="input.os_level"
				placeholder="Select Level"
			>
				<el-option
					v-for="skill_level in skillLevels"
					:key="skill_level"
					:label="skill_level.level"
					:value="skill_level.id"
				>
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item style="margin-left: 25%">
			<svg
				@click="addField(input, osData)"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="40"
				height="44"
				class="ml-2 cursor-pointer"
				style="float: right"
			>
				<path fill="none" d="M0 0h24v24H0z" />
				<path
					fill="green"
					d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
				/>
			</svg>

			<!--          Remove Svg Icon-->
			<svg
				v-show="osData.length > 1"
				@click="removeField(index, osData)"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="40"
				height="44"
				class="ml-2 cursor-pointer"
				style="float: right"
			>
				<path fill="none" d="M0 0h24v24H0z" />
				<path
					fill="#EC4899"
					d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
				/>
			</svg>
		</el-form-item>
		<!-- Skill Levels  -->
		<div class="input-group"></div>
	</div>
</template>
<script>
	export default {
		name: "CreateOs",
		props: ["osData", "allOs", "skillLevels"],
		data() {},
		methods: {
			addField(value, fieldType) {
				fieldType.push({ value: "" });
			},

			removeField(index, fieldType) {
				--index;
				fieldType.splice(index, 1);
			},
		},
	};
</script>