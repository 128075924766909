<template>
	<section class="home-page">
		<header class="header">
			<TopNavbar
				@clicked="sideNavEvent"
				:navFlag="navFlag"
				:name="name"
				:profile_pic="profilePic"
				:role="role"
			/>
		</header>
	</section>

	<section class="skil-matrix">
		<div class="task-intro container" v-if="isSelfEvalEnabled">
			<div style="float: right" v-if="role === 'manager'">
				<el-button type="primary" icon="el-icon-user" @click="teamMemberPage"
					>Team Members</el-button
				>
			</div>
			<h2 class="heading" style="text-align: center">Appraisal</h2>
			<el-divider></el-divider>
			<p>
				RATING GUIDELINES:
				<a
					href="https://docs.google.com/spreadsheets/d/1YucTtZk-CGB8TrcoyiukjBO1glCqS4FIMkFoWNBBiq0/edit?usp=sharing"
					>https://docs.google.com/spreadsheets/d/1YucTtZk-CGB8TrcoyiukjBO1glCqS4FIMkFoWNBBiq0/edit?usp=sharing</a
				>
			</p>
		</div>
	</section>
	<section v-if="isSelfEvalEnabled">
		<div class="container col-md-12">
			<el-card class="box-card" v-if="!is_submitted && !finalSubmission">
				<el-form
					class="matrix-form"
					id="matrix-form"
					:rules="rules"
					ref="skillMatrixFormData"
					@submit="$event.preventDefault()"
					@keypress.enter="__storeSelfEvaluation($event)"
				>
					<div class="row" v-for="quest in questions" :key="quest">
						<div class="column" style="width: 40%">
							<div>
								{{ quest.question }}
							</div>
						</div>
						<div class="column" style="text-align: center; margin-right: 40px">
							<el-form-item>
								<el-rate
									v-model="selfEvaluationData.rating[quest.id]"
									:texts="points"
									show-text
									size="large"
								/>
							</el-form-item>
						</div>
						<div class="column" style="width: 33%">
							<el-form-item>
								<el-input
									v-model="selfEvaluationData.comment[quest.id]"
									type="textarea"
									placeholder="Comments"
								/>
							</el-form-item>
						</div>
					</div>
					<!-- Action btn -->
					<div
						class="flex"
						style="text-align-last: center"
						v-if="questions.length > 0"
					>
						<el-button
							color="#626aef"
							type="info"
							@click="__storeSelfEvaluation(true)"
							:loading="createTaskLoading"
							>Draft</el-button
						>
						<el-button
							class="primary-bg color-white"
							native-type="submit"
							@click="__storeSelfEvaluation(false)"
							:loading="createTaskLoading"
							>Submit</el-button
						>
					</div>
					<div v-else style="text-align-last: center">
						<p>No Questions Added</p>
					</div>
				</el-form>
			</el-card>
			<el-card class="box-card" v-if="is_submitted && !finalSubmission">
				<el-table
					:data="selfRatedData"
					:border="parentBorder"
					style="width: 100%"
				>
					<el-table-column type="expand">
						<template #default="props">
							<div m="4">
								<h3>Manager's Rating</h3>
								<el-table :data="props.row.manager" :border="childBorder">
									<el-table-column label="Manager Name" prop="mgr_name" />
									<el-table-column
										label="Manager Rating"
										width="350"
										#default="scope"
									>
										<el-rate
											v-model="scope.row.mgr_level"
											:texts="points"
											disabled
											show-text
											size="large"
										/>
									</el-table-column>
									<el-table-column label="Manager Comment" prop="mgr_comment" />
								</el-table>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="Question" prop="question" />
					<el-table-column label="Employee Rating" width="350" #default="scope">
						<el-rate
							v-model="scope.row.emp_level"
							:texts="points"
							disabled
							show-text
							size="large"
						/>
					</el-table-column>
					<el-table-column label="Comment" prop="emp_comment" />
				</el-table>
				<div style="text-align-last: center; margin-top: 5px">
					<el-button
						class="primary-bg color-white"
						native-type="button"
						@click="finalSubmissionForm()"
						v-if="!selfRating || isDrafted"
					>
						Edit Evaluation
					</el-button>
				</div>
			</el-card>
			<el-card class="box-card" v-if="finalSubmission">
				<el-form
					class="matrix-form"
					id="matrix-form"
					:rules="rules"
					ref="skillMatrixFormData"
					@submit="$event.preventDefault()"
					@keypress.enter="__storeSelfEvaluation()"
				>
					<div class="row" v-for="quest in questions" :key="quest">
						<div class="column" style="width: 45%">
							<div>
								{{ quest.question }}
							</div>
						</div>
						<div class="column" style="width: 20%; text-align: center">
							<el-form-item>
								<el-rate
									v-model="selfEvaluationData.rating[quest.id]"
									:texts="points"
									show-text
									size="large"
								/>
							</el-form-item>
						</div>
						<div class="column" style="width: 33%">
							<el-form-item>
								<el-input
									v-model="selfEvaluationData.comment[quest.id]"
									type="textarea"
									placeholder="Comments"
								/>
							</el-form-item>
						</div>
					</div>
					<!-- Action btn -->
					<div class="flex" style="text-align-last: center">
						<el-button
							color="#626aef"
							type="info"
							@click="__storeSelfEvaluation(true)"
							:loading="createTaskLoading"
							>Draft</el-button
						>
						<el-button
							class="primary-bg color-white"
							native-type="submit"
							@click="__storeSelfEvaluation(false)"
							:loading="createTaskLoading"
							>Final Submission</el-button
						>
					</div>
				</el-form>
			</el-card>
		</div>
	</section>
	<section v-else>
		<el-empty description="Appraisal is closed"></el-empty>
	</section>
</template>

<script>
	import TopNavbar from "@/components/TopNavbar.vue";
	import SelfEvaluation from "../services/selfevaluation.js";

	export default {
		name: "Self Evaluation",
		data() {
			return {
				questions: [],
				points: [],
				createTaskLoading: false,
				finalData: null,
				isDrafted: false,
				selfEvaluationData: {
					rating: [],
					comment: [],
				},
				selfRatedData: [],
				mgrRatedData: [],
				is_submitted: false,
				avgRating: 0,
				teamMembers: [],
				finalSubmission: false,
				selfRating: true,
				dummy: 1,
				childBorder: true,
				parentBorder: true,
				isSelfEvalEnabled: false,
				profilePic: localStorage.getItem("profile_pic"),
				name: localStorage.getItem("username"),
				role: localStorage.getItem("role"),
			};
		},

		components: {
			TopNavbar,
		},

		mounted() {
			this.isSelfEvaluationEnabled();
			this.questionList();
			this.pointsMappingList();
			this.fetchEvaluationData();
			this.role = localStorage.getItem("role");
		},

		methods: {
			questionList() {
				SelfEvaluation.getQuestionList(localStorage.getItem("P1"))
					.then((res) => {
						this.questions = res.data.questions;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			pointsMappingList() {
				SelfEvaluation.getPointsMappingList()
					.then((res) => {
						this.points = res.data.points;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			finalSubmissionForm() {
				this.finalSubmission = true;
				this.selfRatedData.forEach((element, index) => {
					console.log(index);
					console.log(element);
					this.selfEvaluationData.rating[element.id] = element.emp_level;
					this.selfEvaluationData.comment[element.id] = element.emp_comment;
				});
			},

			__storeSelfEvaluation(draft) {
				console.log(draft);
				this.finalSubmission = false;
				const arr2 = this.selfEvaluationData.comment;
				const arr3 = this.selfEvaluationData.rating;
				console.log(arr3);
				console.log(arr3.length);
				var dataFinal = null;
				var tempData = [];
				var valid = true;
				var qstCount = 0;
				var emptyRatingCount = 0;
				arr3.forEach((element, index) => {
					qstCount++;
					let final = [];
					if (element == 0) {
						if (!draft) {
							valid = false;
						} else {
							emptyRatingCount++;
						}
					}
					final[0] = index;
					final[1] = arr2[index];
					final[2] = this.points[element - 1];
					final[3] = element;

					tempData.push(final);
				});
				if (qstCount == emptyRatingCount && draft) {
					this.$notify({
						title: "Error",
						message: "Please rate at least one questions",
						type: "error",
						position: "bottom-right",
						duration: 2000,
					});
				} else {
					if (!valid) {
						this.$notify({
							title: "Error",
							message: "Please rate all the questions",
							type: "error",
							position: "bottom-right",
							duration: 2000,
						});
					} else {
						var filtered = tempData.filter(function (el) {
							return el[2] != undefined;
						});
						this.finalData = filtered;
						var msg = "";
						if (draft) {
							console.log("DRAFT");
							msg = "Are you sure you want to save as draft ";
						} else {
							console.log("NOT DRAFT");
							msg = "Are you sure you want to submit ";
						}
						this.$confirm(msg, {
							confirmButtonText: "OK",
							cancelButtonText: "Cancel",
							type: "info",
						})
							.then(() => {
								dataFinal = this.finalData;
								console.log(dataFinal);
								SelfEvaluation.storeSelfEvaluation(dataFinal, draft)
									.then(() => {
										this.createTaskLoading = false;
										this.selfEvaluationData = {
											rating: [],
											comment: [],
										};
										this.fetchEvaluationData();
										this.$notify({
											title: "Success",
											message: "Successfully submitted Self Evaulation form",
											type: "success",
											position: "bottom-right",
											duration: 2000,
										});
									})
									.catch((err) => {
										console.log(err);
									});
							})
							.catch(() => {
								// some code
							});
					}
				}
			},

			fetchEvaluationData() {
				SelfEvaluation.fetchSelfEvaluationData(0)
					.then((res) => {
						console.log(res);
						this.selfRatedData = res.data.finaldata;
						this.avgRating = res.data.ratingData.avgRating;
						this.isDrafted = res.data.ratingData.draft;
						if (this.selfRatedData.length > 0) {
							this.is_submitted = true;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},

			isSelfEvaluationEnabled() {
				SelfEvaluation.selfEvaulationStatus()
					.then((res) => {
						if (res.data.status === "closed") {
							this.isSelfEvalEnabled = false;
						} else {
							if (res.data.enabled) {
								this.isSelfEvalEnabled = true;
							}
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},

			teamMemberPage() {
				this.$router.push({ name: "Team Members" });
			},
		},
	};
</script>


<style lang="scss" scoped>
	.skill-matrix {
		margin-top: 50px;
		position: absolute;
		left: 50%;
		width: 400px;
		padding: 40px;
		transform: translate(-50%, -50%);
		background: rgba(0, 0, 0, 0.5);
		box-sizing: border-box;
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
		border-radius: 10px;
	}

	.container {
		margin-right: 100px;
		margin-left: 100px;
	}

	.column {
		float: left;
	}

	/* Clear floats after the columns */
	.row:after {
		content: "";
		display: table;
		clear: both;
	}
</style>