<template>
	<div class="task-intro container">
		<h4 class="heading" style="text-align: left">
			Add Skills/Coding Languages:
		</h4>
	</div>
	<div
		v-for="(input, index) in matrixData"
		:key="`matrixInput-${index}`"
		class="input wrapper"
		style="display: flex"
	>
		<!-- Technology  -->
		<p style="margin-right: 2%">Technology: {{ ++index }}</p>
		<el-form-item prop="tech_id" style="margin-right: 20%">
			<el-select
				style="width: 200%"
				v-model="input.tech_id"
				placeholder="Select Skills/Coding Languages"
			>
				<el-option
					v-for="tech in allTechs"
					:key="tech"
					:label="tech.name"
					:value="tech.id"
				>
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item prop="level" label="">
			<el-select
				v-model="input.level"
				placeholder="Select Level"
				style="width: 200%"
			>
				<el-option
					v-for="skill_level in skillLevels"
					:key="skill_level"
					:label="skill_level.level"
					:value="skill_level.id"
				>
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item style="margin-left: 25%">
			<svg
				@click="addField(input, matrixData)"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="40"
				height="44"
				class="ml-2 cursor-pointer"
				style="float: right"
			>
				<path fill="none" d="M0 0h24v24H0z" />
				<path
					fill="green"
					d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
				/>
			</svg>

			<!--          Remove Svg Icon-->
			<svg
				v-show="matrixData.length > 1"
				@click="removeField(index, matrixData)"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="40"
				height="44"
				class="ml-2 cursor-pointer"
				style="float: right"
			>
				<path fill="none" d="M0 0h24v24H0z" />
				<path
					fill="#EC4899"
					d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
				/>
			</svg>
		</el-form-item>
		<!-- Skill Levels  -->
		<div class="input-group"></div>
	</div>
	<el-divider></el-divider>

	<CreateFrameworks
		:frameworkData="frameworkData"
		:allFrameworks="allFrameworks"
		:skillLevels="skillLevels"
		v-if="!isEdit"
	/>
	<el-divider></el-divider>
	<CreateDb
		:dbData="dbData"
		:allDbs="allDbs"
		:skillLevels="skillLevels"
		v-if="!isEdit"
	/>
	<el-divider></el-divider>
	<CreateIde
		:ideData="ideData"
		:allIde="allIde"
		:skillLevels="skillLevels"
		v-if="!isEdit"
	/>
	<el-divider></el-divider>
	<create-version-tool
		:versionToolsData="versionToolsData"
		:allVersionTools="allVersionTools"
		:skillLevels="skillLevels"
		v-if="!isEdit"
	/>
	<el-divider></el-divider>
	<create-os
		:osData="osData"
		:allOs="allOs"
		:skillLevels="skillLevels"
		v-if="!isEdit"
	/>
	<el-divider></el-divider>
	<CreateWebApi
		:webApiData="webApiData"
		:allWebApis="allWebApis"
		:skillLevels="skillLevels"
		v-if="!isEdit"
	/>
	<el-divider></el-divider>
</template>
<script>
	import CreateFrameworks from "@/components/SkillMatrix/CreateFrameworks.vue";
	import CreateDb from "@/components/SkillMatrix/CreateDb.vue";
	import CreateIde from "@/components/SkillMatrix/CreateIde.vue";
	import CreateVersionTool from "./CreateVersionTool.vue";
	import CreateOs from "./CreateOs.vue";
	import CreateWebApi from "@/components/SkillMatrix/CreateWebApi.vue";

	export default {
		name: "CreateTask",
		props: [
			"matrixData",
			"allTechs",
			"skillLevels",
			"frameworkData",
			"allFrameworks",
			"dbData",
			"allDbs",
			"ideData",
			"allIde",
			"versionToolsData",
			"allVersionTools",
			"osData",
			"allOs",
			"webApiData",
			"allWebApis",
		],
		data() {},
		components: {
			CreateFrameworks,
			CreateDb,
			CreateIde,
			CreateVersionTool,
			CreateOs,
			CreateWebApi,
		},
		methods: {
			addField(value, fieldType) {
				fieldType.push({ value: "" });
			},

			removeField(index, fieldType) {
				--index;
				fieldType.splice(index, 1);
			},
		},
	};
</script>