import http from "../http-common.js";

export default {
  getAllResources() {
    return http.get("/resource/api/resource_list").then((res) => {
      return res;
    });
  },
  editResource(id, data) {
    return http
      .put("/resource/api/resource_list_change/" + id + "/", data)
      .then((res) => {
        return res;
      });
  },
  createResource(data) {
    return http.post("/resource/api/resource_list/", data).then((res) => {
      return res;
    });
  },
  getResourcesRoleTypes() {
    return http.get("/resource/api/get_datas/").then((res) => {
      return res;
    });
  },
};
